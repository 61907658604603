import { useState, useEffect, useMemo } from 'react';
import { parseISO } from 'date-fns';
import { defaultDateTimeString } from '../../../lib/stringHelpers';
import { useRights } from '../../../lib/auth';

import TernTable from '../../../components/TernTable/TernTable';
import SessionModal from '../../../components/Sessions/SessionModal/SessionModal';

function SessionStatusTable({sessions}) {
  const { isAdmin } = useRights();
  const [ tableData, setTableData ] = useState([]);
  const [ showSessionModal, setShowSessionModal ] = useState(null);

  useEffect(() => {
    if (sessions) {
      const sessionData = sessions.map(session => {
        return {
          session: session,
          sessionNum: session.session_num,
          sessionDate: session.session_date ? defaultDateTimeString(parseISO(session.session_date)) : 'Unscheduled',
          menteeSurvey: session.mentee_survey,
          mentorSurvey: session.mentor_survey,
          menteeSurveyDate: session?.mentee_survey?.creation_date ? defaultDateTimeString(parseISO(session.mentee_survey.creation_date)) : null,
          mentorSurveyDate: session?.mentor_survey?.creation_date ? defaultDateTimeString(parseISO(session.mentor_survey.creation_date)) : null,
        }
      })

      setTableData(sessionData.sort((a,b) => a.sessionNum - b.sessionNum ));
    }
  }, [sessions])

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Session #',
        accessor: 'sessionNum',
        collapse: true,
      },
      {
        Header: 'Scheduled For',
        accessor: 'sessionDate',
      },
      {
        Header: 'Mentee Survey',
        accessor: 'menteeSurveyDate',
        Cell: props => props.value ? 
          isAdmin ? <a href="" style={{color: 'hsl(207, 61%, 53%)'}} onClick={(e) => { setShowSessionModal({ isMentor: false, session: props.row.original.session}); e.preventDefault();}}>{props.value}</a> 
          : <span>{props.value}</span> 
          : <span>-</span>,
      },
      {
        Header: 'Mentor Survey',
        accessor: 'mentorSurveyDate',
        Cell: props => props.value ? 
          isAdmin ? <a href="" style={{color: 'hsl(207, 61%, 53%)'}} onClick={(e) => { setShowSessionModal({ isMentor: true, session: props.row.original.session}); e.preventDefault();}}>{props.value}</a> 
          : <span>{props.value}</span> 
          : <span>-</span>,
      },  
    ],
    [isAdmin]
  )

  return <>
      { showSessionModal && <SessionModal isMentor={showSessionModal.isMentor} open={showSessionModal} onClose={() => setShowSessionModal(null)} session={showSessionModal.session} /> }

    <TernTable columns={tableColumns} data={tableData} />
  </>

}

export default SessionStatusTable;